import React from 'react'

const Logo = ({ className, activePerson, activeTime }) => {
    return (<div id="logo"><span className= {`${
        ['present','her'].includes(activePerson) ? 'orange' :
        ['past','me'].includes(activePerson) ? 'green' :              
        ['future','them'].includes(activePerson) ? 'purple' : ''}`}><b className="option active">perfect</b></span>&thinsp;<span className= {`${
          ['present','her'].includes(activeTime) ? 'orange' :
          ['past','me'].includes(activeTime) ? 'green' :              
          ['future','them'].includes(activeTime) ? 'purple' : ''}`}><b className="option active">tenses</b></span>.com</div>)

}   

export default Logo
import React from 'react'
//import Favicon from "react-favicon"
import './App.css'
import Switcher from './components/Switcher/Switcher'
import Sentence from './components/Sentence/Sentence'
import Logo from './components/Logo/Logo'
import database from './data';


const App = () => {

  const personValues = database.labels.personValues
  const timeValues = database.labels.timeValues
  const timeLabels = database.labels.timeLabels  

  const data = database.examples
  const sentenceSwitcherValues = database.options

  const [activePerson,setActivePerson] = React.useState('me')
  const [activeTime,setActiveTime] = React.useState('past')
  const [activeSentence,setActiveSentence] = React.useState(data[1].default.type)

  const theSentence = data[1].values[activePerson][activeTime][activeSentence]

  React.useEffect(()=>{

    // activeSentence IDs does not correspond for
    // Future vs other tenses, hence we're resetting
    // activeSentence to a default position
    
    if (activeTime==='future' && !theSentence) {
      setActiveSentence('intention')
    }
    else if (activeTime!=='future' && !theSentence) {
      setActiveSentence('simple') 
    }
  },[activeTime,theSentence])

  const dynamicColorClass = `${activePerson}-${activeTime}`
  
 

  

  return (
    <>
    <div id="top-content">
      <Logo activePerson={activePerson} activeTime={activeTime}/>
      <h1>Which English Tense Should I Use?</h1>
      <h2>Learn how to use Present Perfect or any other English tenses</h2>
      <div id="filters-and-sentence">
        <div id="filters">
          <div id="small-filters-container">
            <Switcher active={activePerson} label="It's about..." values={personValues} onChange={setActivePerson}/>
            <Switcher active={activeTime} label="It happens in the..." values={timeValues} onChange={setActiveTime}/>
          </div>
          <Switcher id="sentence-switcher-container" active={activeSentence} label="I'm trying to say..." className="sentence-switcher" childClassName={dynamicColorClass} values={sentenceSwitcherValues[activePerson][activeTime]} onChange={setActiveSentence}/>
        </div>
        <Sentence person={activePerson} timeLabel={timeLabels[activeTime][activeSentence]} className={dynamicColorClass}>{theSentence}</Sentence>
      </div>
    </div>
    <footer>Created in NYC by <a href="https://igorsyvets.com/" target="_blank" rel="noreferrer">Igor Syvets</a>. &copy; MMXXII</footer>
    </>
  )
}

export default App;

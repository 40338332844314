import React from 'react'
import Option from '../Option/Option'

const Switcher = ({values, style, onChange, label, active, className, childClassName, props, ...rest}) => {
      
  const [activeElementID,setActiveElementID] = React.useState(active)
  
  
  const plashkaRef = React.useRef(null)
  const switcherRef = React.useRef(null)
 

  const handleClick = (event,itemID) => {

    if (activeElementID===itemID) return
    
    setActiveElementID(itemID)
    liftChangeToParent(itemID)
  }

  React.useEffect(()=>{
    setActiveElementID(active)
  },[active])

  React.useEffect(()=>{

    const parentStyle = window.getComputedStyle(switcherRef.current)
    const parentY = switcherRef.current.offsetTop + parseInt(parentStyle.getPropertyValue('padding-top'))
    
    const activeOption = switcherRef.current.querySelector('.option.active') ? switcherRef.current.querySelector('.option.active') : 0
    const plashkaNode = plashkaRef.current    
    const plashkaPosition = activeOption.offsetTop - parentY

    plashkaNode.style.top = plashkaPosition + "px"

  })

  const liftChangeToParent = (item) => {onChange(item)}


  return (<div className={`switcher-container ${className}`}>
          {label ? <div className='label'>{label}</div> : ''}
          <div 
            className={`switcher ${
              ['present','her'].includes(activeElementID) ? 'orange' :
              ['past','me'].includes(activeElementID) ? 'green' :              
              ['future','them'].includes(activeElementID) ? 'purple' : ''} ${childClassName}`} 
            ref={switcherRef}>
            <div className={`plashka ${activeElementID || active ? 'visible' : ''}`} ref={plashkaRef} />
            {values.map( (item)=>
              <Option 
                active = {activeElementID === item.id}
                value={item.value}
                key={item.id}
                itemID={item.id}

                handleClick={event => handleClick(event,item.id)}
              >
              {item.value}
              </Option>
            )}
          </div>
          </div>)
}

export default Switcher
import React from 'react'
import Tooltip from '../Tooltip/Tooltip'
import './WordAnnotation.css'

const WordAnnotation = ({tooltipText,children}) => {

    //console.log('test')

    return (<>{children}<Tooltip>{tooltipText}</Tooltip></>)

}   

export default WordAnnotation
import React from 'react'

const Option = ({ className = '', active, value, handleClick, ...rest }) => {
    return (<div
        onClick={handleClick}
        className={`option ${className} ${active ? 'active' : ''}`}
        {...rest} />)

}   

export default Option